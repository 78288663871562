import React, { useState } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";

const CreateQuestionForm = () => {
  // State variables to hold form data
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");
  const [endTimeInMillis, setEndTimeInMillis] = useState("");
  const [category, setCategory] = useState("");
  const [options, setOptions] = useState("");
  const [error, setError] = useState("");

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form fields
    if (!type || !question || !endTimeInMillis || !category) {
      setError("All fields are required.");
      return;
    }

    if (Number(endTimeInMillis) < 0) {
      setError("End Time in Millis cannot be negative.");
      return;
    }

    // Clear any previous error message
    setError("");

    // Prepare data to send
    const postData = {
      type,
      question,
      endTimeInMillis: Number(endTimeInMillis), // Ensure it's a number
      category: category.toUpperCase(),
    };

    // Include options field if it's not empty
    if (options.trim() !== "") {
      postData.options = options.trim().split(",");
    }

    try {
      // Send POST request
      const response = await fetch(
        "https://api2.kjgsuskb.in/api/v1/admin/quickmode/question",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
          body: JSON.stringify(postData),
        }
      );

      // Handle response
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        throw new Error(message);
      }

      // Reset form on success
      setType("");
      setQuestion("");
      setEndTimeInMillis("");
      setCategory("");
      setOptions("");
      alert("Question created successfully!");
    } catch (error) {
      console.error("Error during form submission:", error);
      setError(`Failed to create question: ${error.message}`);
    }
  };

  return (
    <Container fluid style={styles.container}>
      <Row className="justify-content-md-center">
        <Col style={{ width: "700px" }}>
          <Card style={styles.card}>
            <Card.Body>
              <Card.Title style={styles.title}>
                Create a New Question
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                {/* Type field */}
                <Form.Group controlId="type" className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                    style={styles.select}
                  >
                    <option value="">Select Type</option>
                    <option value="input">Input</option>
                    <option value="twooption">Two Option</option>
                    <option value="multioption">Multi Option</option>
                  </Form.Select>
                </Form.Group>

                {/* Question field */}
                <Form.Group controlId="question" className="mb-3">
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    required
                    style={styles.input}
                  />
                </Form.Group>

                {/* End Time in Millis field */}
                <Form.Group controlId="endTimeInMillis" className="mb-3">
                  <Form.Label>End Time (in millis)</Form.Label>
                  <Form.Control
                    type="number"
                    value={endTimeInMillis}
                    onChange={(e) => setEndTimeInMillis(e.target.value)}
                    required
                    min="0"
                    style={styles.input}
                  />
                </Form.Group>

                {/* Category field */}
                <Form.Group controlId="category" className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                    style={styles.input}
                  />
                </Form.Group>

                {/* Options field */}
                <Form.Group controlId="options" className="mb-3">
                  <Form.Label>Options (comma-separated)</Form.Label>
                  <Form.Control
                    type="text"
                    value={options}
                    onChange={(e) => setOptions(e.target.value)}
                    style={styles.input}
                  />
                </Form.Group>

                {/* Error message */}
                {error && <Alert variant="danger">{error}</Alert>}

                {/* Submit button */}
                <Button variant="primary" type="submit" style={styles.button}>
                  Create Question
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Inline CSS styles
const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(135deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
  },
  card: {
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  },
  title: {
    color: "#ff6b6b",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.5em",
  },
  select: {
    border: "2px solid #ff9a9e",
    borderRadius: "10px",
    transition: "border-color 0.2s ease-in-out",
  },
  input: {
    border: "2px solid #ff9a9e",
    borderRadius: "10px",
    transition: "border-color 0.2s ease-in-out",
  },
  button: {
    background: "linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%)",
    border: "none",
    borderRadius: "50px",
    padding: "10px 20px",
    width: "100%",
    transition: "background 0.2s ease-in-out, transform 0.2s ease-in-out",
  },
};

// Add hover effect dynamically
styles.card[":hover"] = {
  transform: "translateY(-5px)",
  boxShadow: "0 15px 35px rgba(0, 0, 0, 0.15)",
};
styles.button[":hover"] = {
  background: "linear-gradient(135deg, #ff6b6b 0%, #ff9a9e 100%)",
  transform: "scale(1.05)",
};

export default CreateQuestionForm;
