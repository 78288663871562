import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";

const NotificationActiveUser = () => {
  const location = useLocation();

  const initialValues = {
    mobile: location.state,
    title: "",
    body: "",
    image: "",
  };

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        "https://api2.kjgsuskb.in/api/v1/admin/user/send-notification",
        values,
        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      );
      console.log("Response:", response.data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const showAlert = () => {
    alert("Notification created successfully!");
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded shadow">
      <h1 className="text-3xl font-semibold mb-4 text-center text-blue-600">
        Create a Notification
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label htmlFor="title" className="block font-medium mb-1">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className="w-full border rounded p-2"
            {...formik.getFieldProps("title")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="body" className="block font-medium mb-1">
            Body
          </label>
          <textarea
            id="body"
            name="body"
            className="w-full border rounded p-2"
            rows="4"
            {...formik.getFieldProps("body")}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="image" className="block font-medium mb-1">
            Image URL
          </label>
          <input
            type="text"
            id="image"
            name="image"
            className="w-full border rounded p-2"
            {...formik.getFieldProps("image")}
          />
        </div>
        <button
          type="submit"
          onClick={showAlert}
          className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 w-full"
        >
          Create Notification
        </button>
      </form>
    </div>
  );
};

export default NotificationActiveUser;
