import React, { useState, useEffect } from "react";
import Modal1 from "./Modal1";
import Modal2 from "./Modal2";
import axios from "axios";

function Bank() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [created, setCreated] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page

  const fetchFunction = async () => {
    await axios
      .get(
        `https://api2.kjgsuskb.in/api/v1/admin/created-withdrawal?page=${page}&itemsPerPage=${itemsPerPage}`,
        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCreated(response.data?.created);
        setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page
      });
  };

  useEffect(() => {
    fetchFunction();
  }, [page, itemsPerPage]);

  const handleApprove = async (id) => {
    setSelectedId(id);
    setIsModalOpen(true);
    // await performAction();
  };

  const handleReject = async (id) => {
    setSelectedId(id);
    setIsRejectModalOpen(true);
    // await performAction();
  };

  const performAction = async () => {
    // Perform your action here, like updating data on the server
    // Example:
    // await axios.put(`your-api-endpoint/${selectedId}`, { data });
    // Reload the page
    window.location.reload();
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
  };

  const getAggregate = () => {
    const totalAmount = created.reduce((a, b) => a + b.amount, 0);
    return totalAmount;
  };

  return (
    <div>
      <table className="table-auto m-10 " style={{ zoom: 0.6 }}>
        <thead>
          <tr>
            <th className="px-4 py-2 font-bold border-2">serial</th>
            <th className="px-4 py-2 font-bold border-2">mobile</th>
            <th className="px-4 py-2 font-bold border-2">Amount</th>
            <th className="px-4 py-2 font-bold border-2">Useruid</th>
            <th className="px-4 py-2 font-bold border-2">paymentCreds</th>
            <th className="px-4 py-2 font-bold border-2">status</th>
            <th className="px-4 py-2 font-bold border-2">Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {created?.map((res, index) => {
            return (
              <tr key={res._id} className="hover:bg-gray-100">
                <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                  {startIndex + index} {/* Display the increasing index */}
                </td>
                <td className="px-4 py-2 border-2">{res.mobile}</td>
                <td className="px-4 py-2 border-2">{res.amount}</td>
                <td className="px-4 py-2 border-2">{res.userUid}</td>
                <td className="px-4 py-2 border-2">
                  {JSON.stringify(res.paymentCreds)}
                </td>
                <td className="px-4 py-2 border-2">{res.status}</td>

                <td className="px-4 py-2 border-2">{res?.timestamp}</td>

                <td className="px-4 py-2 border-2">
                  <button
                    onClick={() => handleApprove(res._id)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold mx-1 py-1 px-2 rounded"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleReject(res._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 my-2 rounded"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center mt-4">
        <button
          onClick={(event) => handlePageChange(event, page - 1)}
          disabled={page === 1}
          className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
        >
          Previous
        </button>
        <div className="mx-2"></div>
        <button
          onClick={(event) => handlePageChange(event, page + 1)}
          disabled={created?.length < itemsPerPage}
          className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
        >
          Next
        </button>
      </div>

      <div className="flex justify-center mt-4">
        <label className="mx-2 font-bold">Items per page:</label>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={2000}>2000</option>
          <option value={5000}>5000</option>
        </select>
      </div>
      <div class="mt-8">
        <h4 class="text-xl font-bold text-gray-900 text-center">
          Total Amount
        </h4>
        <h5 class="text-lg font-semibold text-gray-500 text-center">
          {getAggregate()}
        </h5>
        <div class="text-center">
          <div class="flex justify-center">
            <div class="text-4xl font-bold text-gray-900">
              <b class="animate-pulse">{getAggregate()}</b>
            </div>
            <div class="text-2xl font-bold text-gray-500">
              <b>INR</b>
            </div>
          </div>
          <div class="h-10 w-10 rounded-full bg-gray-300 mx-auto"></div>
        </div>
      </div>

      {isModalOpen ? (
        <Modal1 setIsModalOpen={setIsModalOpen} id={selectedId} />
      ) : null}
      {isRejectModalOpen ? (
        <Modal2 setIsRejectModalOpen={setIsRejectModalOpen} id={selectedId} />
      ) : null}
    </div>
  );
}

export default Bank;
