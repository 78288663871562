import React, { useState } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";

const CreateAdd = () => {
  // State variables to hold form data
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear any previous error message
    setError("");

    // Prepare form data
    const formData = new FormData();
    formData.append("title", "Refer And Earn");
    formData.append("type", "REFER_AND_EARN");
    if (file) {
      formData.append("file", file);
    }

    try {
      // Send POST request
      const response = await fetch(
        "https://api2.kjgsuskb.in/api/v1/admin/addvertisment/create",
        {
          method: "POST",
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
          body: formData,
        }
      );

      // Handle response
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        throw new Error(message);
      }

      // Reset form on success
      setFile(null);
      alert("Addvertisment created successfully!");
    } catch (error) {
      console.error("Error during form submission:", error);
      setError(`Failed to create addvertisement: ${error.message}`);
    }
  };

  return (
    <Container fluid style={styles.container}>
      <Row className="justify-content-md-center">
        <Col style={{ width: "700px" }}>
          <Card style={styles.card}>
            <Card.Body>
              <Card.Title style={styles.title}>
                Create a New Addvertisement
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                {/* Title field */}
                {/* <Form.Group controlId="title" className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    style={styles.input}
                  />
                </Form.Group> */}

                {/* Type field */}
                {/* <Form.Group controlId="type" className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    required
                    style={styles.select}
                  >
                    <option value="">Select Type</option>
                    <option value="MATCH">MATCH</option>
                    <option value="ADVERTISMENT">ADVERTISMENT</option>
                    <option value="REFER_AND_EARN">REFER_AND_EARN</option>
                  </Form.Select>
                </Form.Group> */}

                {/* File upload field */}
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>Upload File</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={styles.input}
                  />
                </Form.Group>

                {/* Add URL field */}
                {/* <Form.Group controlId="addUrl" className="mb-3">
                  <Form.Label>Add URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={addUrl}
                    onChange={(e) => setAddUrl(e.target.value)}
                    style={styles.input}
                  />
                </Form.Group> */}

                {/* Error message */}
                {error && <Alert variant="danger">{error}</Alert>}

                {/* Submit button */}
                <Button variant="primary" type="submit" style={styles.button}>
                  Create Add
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Inline CSS styles
const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(135deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
  },
  card: {
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  },
  title: {
    color: "#ff6b6b",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.5em",
  },
  select: {
    border: "2px solid #ff9a9e",
    borderRadius: "10px",
    transition: "border-color 0.2s ease-in-out",
  },
  input: {
    border: "2px solid #ff9a9e",
    borderRadius: "10px",
    transition: "border-color 0.2s ease-in-out",
  },
  button: {
    background: "linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%)",
    border: "none",
    borderRadius: "50px",
    padding: "10px 20px",
    width: "100%",
    transition: "background 0.2s ease-in-out, transform 0.2s ease-in-out",
  },
};

// Add hover effect dynamically
styles.card[":hover"] = {
  transform: "translateY(-5px)",
  boxShadow: "0 15px 35px rgba(0, 0, 0, 0.15)",
};
styles.button[":hover"] = {
  background: "linear-gradient(135deg, #ff6b6b 0%, #ff9a9e 100%)",
  transform: "scale(1.05)",
};

export default CreateAdd;
