import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const PostAnswerPage = () => {
  const location = useLocation();
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    // Validation
    if (!answer) {
      setError("Answer is required");
      return;
    }

    // API call
    fetch("https://api2.kjgsuskb.in/api/v1/admin/quickmode/question/answer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      body: JSON.stringify({
        id: location.state,
        answer,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to post answer");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Post Answer</h2>
      <div style={styles.form}>
        <div style={styles.field}>
          <label style={styles.label}>ID:</label>
          <input
            type="text"
            value={location.state}
            style={styles.input}
            readOnly
          />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Answer:</label>
          <input
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            style={styles.input}
          />
        </div>
        <button onClick={handleSubmit} style={styles.button}>
          Post Answer
        </button>
        {error && <p style={styles.error}>{error}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    minHeight: "100vh",
    background: "#e1f5fe",
  },
  heading: {
    marginBottom: "20px",
    color: "#000",
  },
  form: {
    background: "#fff",
    padding: "20px",
    borderRadius: "5px",
  },
  field: {
    marginBottom: "20px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    background: "#fdd835",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginTop: "10px",
  },
};

export default PostAnswerPage;
