import React, { useState, useEffect } from "react";
import axios from "axios";
import logoutImage from "../data (1)/data/logout.png";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [pools, setPools] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userQuestion, setUserQuestion] = useState(0);

  const [metricsData, setMetricsData] = useState([]);
  const [poolmetrics, setpoolmetrics] = useState([]);
  const [homepagemetrics, sethomepagemetrics] = useState([]);
  const [activeusers, setactiveusers] = useState(0);
  const [inactiveusers, setinactiveusers] = useState(0);
  let navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    // console.log( localStorage.removeItem('Token'))
    navigate("/");
    window.location.reload(false);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  async function fetchPools() {
    try {
      const response2 = await axios.get(
        "https://api2.kjgsuskb.in/api/v1/admin/metrics",
        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      );
      setMetricsData(response2.data.metrics.signupmetrics);
      setpoolmetrics(response2.data.metrics.poolplayermetrics);
      sethomepagemetrics(response2.data.metrics.homepagemetrics);
      setactiveusers(response2.data.metrics.activeusers);
      setinactiveusers(response2.data.metrics.inactiveusers);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserCount() {
    try {
      const response = await axios.get(
        "https://api2.kjgsuskb.in/api/v1/admin/users/length",
        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      );

      setUserCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPools();
  }, []);

  const onGetUserCount = () => {
    fetchUserCount();
  };

  return (
    <>
      <div class="mt-24 " style={{ zoom: 0.6 }}>
        <div class="flex justify-between items-start mb-4">
          <div class="text-right">
            <button
              onClick={onLogout}
              class="rounded-full shadow-lg animate-pulse"
            >
              <img src={logoutImage} alt="Logout" class="w-8 h-10" />
            </button>
          </div>
        </div>

        <div class="mt-8 mx-4 md:mx-8">
          <div>
            <button
              onClick={onGetUserCount}
              class="bg-gradient-to-r hover:bg-gradient-to-r from-blue-400 to-green-600 text-white font-bold py-2 px-4 rounded-full shadow-lg"
            >
              Get User Count
            </button>

            <div class="mt-4">
              <p class="text-lg text-gray-800">
                <span class="inline-block bg-gradient-to-r from-yellow-500 to-red-500 text-white py-1 px-2 ml-2 rounded-full shadow-lg text-4xl font-extrabold">
                  {userCount}
                </span>
              </p>
            </div>
          </div>
          <br />
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              backgroundColor: "#f4f4f4",
              margin: 0,
              padding: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  color: "#333",
                  marginBottom: "15px",
                }}
              >
                Active users:{" "}
                <span
                  style={{
                    color: "#007bff",
                    fontWeight: "bold",
                  }}
                >
                  {activeusers}
                </span>
              </h2>
              <div style={{ marginBottom: "15px" }}>
                Inactive users:{" "}
                <span
                  style={{
                    color: "#007bff",
                    fontWeight: "bold",
                  }}
                >
                  {inactiveusers}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row items-center flex-wrap mx-4 md:mx-8 my-4 md:my-8 w-full rounded-lg shadow-lg bg-white">
          <h2
            class="text-3xl font-bold mt-4 mb-8 w-full text-center text-white 
    bg-gradient-to-r from-blue-500 via-green-500 to-blue-500 
    rounded-md p-4 shadow-md
    tracking-wider 
    shadow-text"
          >
            Metrices
          </h2>

          <div class="flex flex-row mx-4 md:mx-8 my-4 md:my-8 w-full rounded-lg shadow-lg bg-white">
            <div class="w-full md:w-1/3 p-4">
              <h3
                class="text-1xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-green-400 to-green-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
              >
                Signup Metrics
              </h3>

              <div className="chart-container">
                {/* <Line
                data={{
                  labels: metricsData.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "Signup Count",
                      data: metricsData.map((metric) => metric.count),
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                    },
                  ],
                }}
              /> */}
              </div>
              {
                <table
                  className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                  style={{ zoom: 0.7 }}
                >
                  <thead className="bg-blue-500">
                    <tr>
                      {/* Metric Name heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Created At
                      </th>
                      {/* Metric Value heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Count
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {metricsData?.map((metric, index) => (
                        <tr
                          key={metric.createdAt}
                          className={`border-b border-gray-200 ${
                            index % 2 === 0 ? "bg-gray-100" : ""
                          } hover:bg-gray-200`}
                        >
                          {/* Metric Name column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.createdAt}
                          </td>
                          {/* Metric Value column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </table>
              }
            </div>

            <div class="w-full md:w-1/3 p-4">
              <h3
                class="text-1xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-green-400 to-green-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
              >
                Game Played Metrics
              </h3>

              <div className="chart-container">
                {/* <Line
                data={{
                  labels: poolmetrics.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "Game Played Count",
                      data: poolmetrics.map((metric) => metric.count),
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                    },
                  ],
                }}
              /> */}
              </div>
              {
                <table
                  className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                  style={{ zoom: 0.7 }}
                >
                  <thead className="bg-blue-500">
                    <tr>
                      {/* Metric Name heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Created At
                      </th>
                      {/* Metric Value heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Count
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {poolmetrics.map((metric, index) => (
                        <tr
                          key={metric.createdAt}
                          className={`border-b border-gray-200 ${
                            index % 2 === 0 ? "bg-gray-100" : ""
                          } hover:bg-gray-200`}
                        >
                          {/* Metric Name column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.createdAt}
                          </td>
                          {/* Metric Value column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </table>
              }
            </div>

            <div class="w-full md:w-1/3 p-4">
              <h3
                class="text-1xl font-bold text-center mt-4 mb-8 text-white 
    bg-gradient-to-r from-green-400 to-green-600
    rounded-lg p-4 shadow-sm
    tracking-wider"
              >
                Daily App Open Metrics
              </h3>

              <div className="chart-container">
                {/* <Line
                data={{
                  labels: homepagemetrics.map((metric) => metric.createdAt),
                  datasets: [
                    {
                      label: "App Open Count",
                      data: homepagemetrics.map((metric) => metric.count),
                      backgroundColor: "rgba(255, 99, 132, 0.5)",
                    },
                  ],
                }}
              /> */}
              </div>

              {
                <table
                  className="mx-4 md:mx-8 my-4 md:my-8 rounded-lg shadow-lg bg-white border border-gray-300 overflow-hidden"
                  style={{ zoom: 0.7 }}
                >
                  <thead className="bg-blue-500">
                    <tr>
                      {/* Metric Name heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Created At
                      </th>
                      {/* Metric Value heading */}
                      <th className="px-4 py-2 font-bold text-lg text-white">
                        Count
                      </th>
                    </tr>
                  </thead>
                  {
                    <tbody>
                      {homepagemetrics.map((metric, index) => (
                        <tr
                          key={metric.createdAt}
                          className={`border-b border-gray-200 ${
                            index % 2 === 0 ? "bg-gray-100" : ""
                          } hover:bg-gray-200`}
                        >
                          {/* Metric Name column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.createdAt}
                          </td>
                          {/* Metric Value column */}
                          <td className="px-4 py-2 text-lg font-semibold text-gray-800">
                            {metric.count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
