import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
const AllQuestion = () => {
  const tagColors = ['bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-red-500', 'bg-purple-500', 'bg-orange-500', 'bg-pink-500'];
  let navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page

  const fetchFunction = async () => {
    await axios
      .get(
        `https://api2.kjgsuskb.in/api/v1/admin/question?page=${page}&itemsPerPage=${itemsPerPage}`,

        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setUser(response.data?.questions);
        setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page
      });
  };
  useEffect(() => {
    fetchFunction();

    return () => {
      fetchFunction();
    };
  }, [page, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const deleteQuestionHandler = (id) => {
    console.log("deleteQ ", id);

    // Body create
    let apiBody = {
      id,
    };

    // doing api call
    var config = {
      method: "delete",
      url: "https://api2.kjgsuskb.in/api/v1/admin/question/delete",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("question deleted");
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const updateQuestionHandler = (question) => {
    navigate("/updatequestion", { state: question });
  };

  if (user?.length) {
    return (
      <div className="flex flex-col ">
        {/* <Header category="Page" title="All Questions" /> */}
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-scroll border rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-200 sm:divide-y-0"
                style={{ zoom: 0.5 }}
              >
                <thead className="bg-red-50">
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Serial
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      _id
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      category
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      time
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      points
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      tags
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      question
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      correctoption
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      difficulty
                    </th>

                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      isUnlisted
                    </th>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      createdAt
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {user.map((res, index) => {
                    return (
                      <tr key={index} className="bg-white hover:bg-gray-100">
                        <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                          {startIndex + index}{" "}
                          {/* Display the increasing index */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?._id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.time}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.points}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-wrap gap-2">
                            {res?.tags.map((tag, index) => (
                              <span
                                key={index}
                                className={`inline-block px-3 py-1 bg-blue-500 text-white rounded-full text-sm ${tagColors[index % tagColors.length]}`}
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => {
                              setShowQuestion(!showQuestion);
                            }}
                          >
                            {showQuestion ? res?.question : ""}
                          </button>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.correctOption}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.difficulty}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                        {res.isUnlisted ? "true" : "false"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.createdAt).toLocaleString()}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap whitespace-wrap break-normal">
                          <button
                            className="shadow bg-red-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteQuestionHandler(res._id);
                            }}
                          >
                            question delete
                          </button>
                        </td>

                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap whitespace-wrap break-normal">
                          <button
                            className="shadow bg-blue-700 hover:bg-blue-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              updateQuestionHandler(res);
                            }}
                          >
                            Update
                          </button>
                        </td>

                        {/* <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap whitespace-wrap break-normal">
                          <button
                            className="shadow bg-red-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/updatequestion", { state: res });
                            }}
                          >
                            Reuse
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page - 1)}
            disabled={page === 1}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Previous
          </button>
          <div className="mx-2"></div>
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page + 1)}
            disabled={user?.length < itemsPerPage}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Next
          </button>
        </div>

        <div className="flex justify-center mt-4">
          <label className="mx-2 font-bold" style={{ zoom: 0.7 }}>
            Items per page:
          </label>
          <select
            style={{ zoom: 0.7 }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={2000}>2000</option>

          </select>
        </div>
      </div>
    );
  }
};

export default AllQuestion;
