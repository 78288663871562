import React from "react";
import { useFormik } from "formik";
import axios from "axios";

const CreatePool = () => {
  const formik = useFormik({
    initialValues: {
      category: "",
      quizid: "",
      prizemoney: 0,
      totalPlayer: 0,
      expiryTime: "",
      entryFee: 0,
    },
    onSubmit: async (values) => {
      // Body create
      let apiBody = {
        ...values,
        expiryTime: Date.parse(values.expiryTime),
      };

      // doing api call
      var config = {
        method: "post",
        url: "https://api2.kjgsuskb.in/api/v1/admin/pool/create",
        headers: {
          "Content-Type": "application/json",
          "bwb-header":
            "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
        },
        data: JSON.stringify(apiBody),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("pool created");
        })
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });
  return (
    <div className="flex flex-col justify-center items-center border-2 py-8 px-6 shadow rounded-lg sm:px-10 bg-blue-400">

      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        style={{ zoom: 0.8 }}
        onSubmit={formik.handleSubmit}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="category"
            >
              Category
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              type="text"
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="quizid"
            >
              Quiz id
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="quizid"
              name="quizid"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.quizid}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="prizemoney"
            >
              Prize Money
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="prizemoney"
              name="prizemoney"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.prizemoney}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="totalplayer"
            >
              Total Player
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="totalplayer"
              name="totalPlayer"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.totalplayer}
            />
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="expirytime"
            >
              Expiry Time
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="expirytime"
              name="expiryTime"
              type="datetime-local"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.expirytime}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="price"
            >
              Entry Fee
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="price"
              name="entryFee"
              type="number"
              min="0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
            />
          </div>
        </div>

        <button
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Create Pool
        </button>
      </form>
    </div>
  );
};

export default CreatePool;
