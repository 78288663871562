import React, { useState } from "react";
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";

const GameImage = () => {
  const location = useLocation();
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const formData = new FormData();
    formData.append("matchId", location.state);

    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await fetch(
        "https://api2.kjgsuskb.in/api/v1/admin/match/image",
        {
          method: "POST",
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        throw new Error(message);
      }

      setFile(null);
      alert("Image created successfully!");
    } catch (error) {
      console.error("Error during form submission:", error);
      setError(`Failed to create Image: ${error.message}`);
    }
  };

  return (
    <Container fluid style={styles.container}>
      <Row className="justify-content-md-center">
        <Col style={{ width: "700px" }}>
          <Card style={styles.card}>
            <Card.Body>
              <Card.Title style={styles.title}>Image For a Game</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>Upload File</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={styles.input}
                  />
                </Form.Group>
                {error && <Alert variant="danger">{error}</Alert>}
                <Button variant="primary" type="submit" style={styles.button}>
                  Create Add
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(135deg, #ffecd2 0%, #fcb69f 100%)",
  },
  card: {
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    backgroundColor: "#fff7e6",
  },
  title: {
    color: "#ff7f50",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.5em",
  },
  input: {
    border: "2px solid #ffcc99",
    borderRadius: "10px",
    transition: "border-color 0.2s ease-in-out",
  },
  button: {
    background: "linear-gradient(135deg, #ff9a76 0%, #ff6f61 100%)",
    border: "none",
    borderRadius: "50px",
    padding: "10px 20px",
    width: "100%",
    transition: "background 0.2s ease-in-out, transform 0.2s ease-in-out",
  },
};

styles.card[":hover"] = {
  transform: "translateY(-5px)",
  boxShadow: "0 15px 35px rgba(0, 0, 0, 0.15)",
};
styles.button[":hover"] = {
  background: "linear-gradient(135deg, #ff7f50 0%, #ff5722 100%)",
  transform: "scale(1.05)",
};

export default GameImage;
