import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const QuestionListPage = () => {
  let navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://api2.kjgsuskb.in/api/v1/admin/quickmode/questions/active", {
      headers: {
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setQuestions(data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  const handleDelete = (id) => {
    // Make a delete API call
    fetch("https://api2.kjgsuskb.in/api/v1/admin/quickmode/question/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete question");
        }
        // Refresh the question list after successful deletion
        return fetch(
          "https://api2.kjgsuskb.in/api/v1/admin/quickmode/questions/active",
          {
            headers: {
              "bwb-header":
                "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
            },
          }
        );
      })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handlePostAnswer = (id) => {
    navigate("/PostAnswer", { state: id });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={styles.container}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.header}>_id</th>
            <th style={styles.header}>type</th>
            <th style={styles.header}>category</th>
            <th style={styles.header}>question</th>
            <th style={styles.header}>options</th>
            <th style={styles.header}>endTimeInMillis</th>
            <th style={styles.header}>isResultDeclared</th>
            <th style={styles.header}>answer</th>
            <th style={styles.header}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question) => (
            <tr key={question._id} style={styles.row}>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question._id}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.type}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.category}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.question}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.options.join(", ")}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.endTimeInMillis}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.isResultDeclared ? "Yes" : "No"}
              </td>
              <td style={{ ...styles.cell, fontSize: "0.8em" }}>
                {question.answer}
              </td>
              <td style={{ ...styles.buttonCell, fontSize: "0.8em" }}>
                <button
                  onClick={() => handlePostAnswer(question._id)}
                  style={{
                    ...styles.button,
                    background: "#fdd835",
                    fontWeight: "bold",
                  }}
                >
                  Post Answer
                </button>

                <button
                  onClick={() => handleDelete(question._id)}
                  style={{
                    ...styles.button,
                    background: "#FF7F7F",
                    fontWeight: "bold",
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  container: {
    background: "#e1f5fe",
    padding: "20px",
    minHeight: "100vh",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  header: {
    color: "black",
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid black",
  },
  row: {
    borderBottom: "1px solid #000000",
  },
  cell: {
    padding: "10px",
    borderBottom: "1px solid #000000",
  },
  buttonCell: {
    textAlign: "center",
    fontSize: "0.8em",
    borderBottom: "1px solid #000000",
  },
  button: {
    padding: "5px 10px",
    margin: "0 5px",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default QuestionListPage;
