import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UsersPerGame = () => {
  let navigate = useNavigate();
  const [reportedUsers, setReportedUsers] = useState(new Set());

  const [user, setUser] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);

  // res.matchId, res.matchFormat
  const manualResultHandler = (matchId, matchFormat) => {
    navigate("/ManualResult", { state: { matchId, matchFormat } });
  };

  const postImage = (matchId) => {
    navigate("/GameImage", { state: matchId });
  };

  // postImage

  const seeDetailedReport = async (matchId) => {
    try {
      const response = await axios.get(
        `https://api2.kjgsuskb.in/api/v1/admin/match/report?matchId=${matchId}`,
        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data received
      const blob = new Blob([response.data], { type: "text/html" });

      // Create a URL for the Blob object
      const url = URL.createObjectURL(blob);

      // Open the URL in a new tab
      window.open(url, "_blank");
    } catch (error) {
      console.log("Error fetching match data:", error);
    }
  };

  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page
  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  const filterUser = () => {
    const filteredData = user.filter((res) => {
      const { uid, name, mobile, pan, accountNumber, ifsc, upi } = res;
      const lowerCaseQuery = searchQuery.toLowerCase();

      return (
        String(uid).toLowerCase().includes(lowerCaseQuery) ||
        String(name).toLowerCase().includes(lowerCaseQuery) ||
        String(mobile).toLowerCase().includes(lowerCaseQuery) ||
        String(pan).toLowerCase().includes(lowerCaseQuery) ||
        String(accountNumber).toLowerCase().includes(lowerCaseQuery) ||
        String(ifsc).toLowerCase().includes(lowerCaseQuery) ||
        String(upi).toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredUser(filteredData.length ? filteredData : user);
  };

  useEffect(() => {
    const fetchFunction = async () => {
      try {
        const response = await axios.get(
          `https://api2.kjgsuskb.in/api/v1/admin/get-orders-by-game?page=${page}&itemsPerPage=${itemsPerPage}`,
          {
            headers: {
              "bwb-header":
                "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
            },
          }
        );
        setUser(response.data?.data);
        setStartIndex((page - 1) * itemsPerPage + 1);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };
    fetchFunction();
  }, [page, itemsPerPage]);

  useEffect(() => {
    filterUser();
  }, [user, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  // if (user.length) {

  if (!user || user.length === 0) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center mt-2 space-x-2">
        <input
          style={{ zoom: 0.7 }}
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchQueryChange}
          className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />

        <button
          style={{ zoom: 0.7 }}
          onClick={(event) => handlePageChange(event, page - 1)}
          disabled={page === 1}
          className="px-2 py-1 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 text-blue-500"
        >
          Previous
        </button>

        <span className="mr-1" style={{ zoom: 0.7 }}>
          Page:
        </span>
        <input
          type="number"
          value={page}
          onChange={(event) => setPage(parseInt(event.target.value))}
          className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />

        <button
          style={{ zoom: 0.7 }}
          onClick={(event) => handlePageChange(event, page + 1)}
          disabled={user?.length < itemsPerPage}
          className="px-2 py-1 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 text-blue-500"
        >
          Next
        </button>

        <label className="mr-1 font-bold" style={{ zoom: 0.7 }}>
          Items per page:
        </label>
        <select
          style={{ zoom: 0.7 }}
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className="px-2 py-1 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-offset-2"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={100}>100</option>

          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={10000}>10000</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200 sm:divide-y-0"
              style={{ zoom: 0.42 }}
            >
              <thead className="bg-red-50">
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Serial
                  </th>
                  {Object.keys(user[0]).map((res, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {res === "walletCredentials" ? "coins" : res}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredUser.map((res, index) => {
                  return (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                        {startIndex + index}{" "}
                        {/* Display the increasing index */}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.matchId}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.matchType}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.seriesName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.matchFormat}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.matchDesc}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.totalOvers}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.team1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.team2}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.totalPlayersJoined}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-orange-700 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            seeDetailedReport(res.matchId);
                          }}
                        >
                          Detailed Report
                        </button>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-green-700 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            manualResultHandler(res.matchId, res.matchFormat);
                          }}
                        >
                          Manual Result
                        </button>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-blue-700 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            postImage(res.matchId);
                          }}
                        >
                          Game Image
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPerGame;
