import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Modal2({ setIsRejectModalOpen, id }) {
  let navigate = useNavigate();
  function rejectHandler() {
    setIsRejectModalOpen(false);

    const apiBody = {
      id: document.getElementsByName("id")[0].value,
      remark: document.getElementsByName("remark")[0].value,
    };

    // doing api call
    var config = {
      method: "post",
      url: "https://api2.kjgsuskb.in/api/v1/admin/rejected-withdrawal",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("txn set as rejected");
        window.location.reload();
      })
      .catch(function (error) {
        alert("something went wrong");
        window.location.reload();

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });

    
    
  }
  return (
    <>
      <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9V2M9.5 4.096L2 9l7.5 4.904"
                  ></path>
                </svg>
              </div> */}
              <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 text-center bg-orange-300 font-medium text-gray-900">
                  reject
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      <input
                        type="text"
                        className="w-full border-4"
                        placeholder="Enter ID here"
                        name="id"
                        value={id}
                        readonly
                        required
                      />
                      <div className="mt-2">
                        <textarea
                          placeholder="Enter remark here"
                          className="w-full my-4 border-4"
                          name="remark"
                          required
                        ></textarea>
                      </div>
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5
  "
                onClick={() => setIsRejectModalOpen(false)}
              >
                Cancel
              </button>

              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent mx-2 px-4 py-2 bg-blue-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5
  "
                onClick={() => rejectHandler()}
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
export default Modal2;
