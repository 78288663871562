import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ManualResult = () => {
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      matchId: location.state.matchId,
      matchFormat: location.state.matchFormat,
      over: 0,
      inningsId: 0,
      firstball: 0,
      secondball: 0,
      thirdball: 0,
      fourthball: 0,
      fifthball: 0,
      sixthball: 0,
    },
    onSubmit: async (values) => {
      // Body create
      let apiBody = {
        ...values,
      };

      // doing api call
      var config = {
        method: "post",
        url: "https://api2.kjgsuskb.in/api/v1/admin/manual-result",
        headers: {
          "Content-Type": "application/json",
          "bwb-header":
            "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
        },
        data: JSON.stringify(apiBody),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          alert("manual result done");
        })
        .catch(function (error) {
          alert("something went wrong");

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  });
  return (
    <div className=" flex flex-col justify-center items-center border-2 bg-gradient-to-r from-indigo-500 ...Name py-8 px-6 shadow rounded-lg sm:px-10 ">
      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        onSubmit={formik.handleSubmit}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="matchId"
            >
              MatchId
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              type="number"
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              name="matchId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.matchId}
              readOnly
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="over"
            >
              over
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="over"
              name="over"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.over}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="inningsId"
            >
              inningsId
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inningsId"
              name="inningsId"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.inningsId}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="firstball"
            >
              firstball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="firstball"
              name="firstball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstball}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="secondball"
            >
              secondball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="secondball"
              name="secondball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secondball}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="thirdball"
            >
              thirdball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="thirdball"
              name="thirdball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.thirdball}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="fourthball"
            >
              fourthball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="fourthball"
              name="fourthball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fourthball}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="fifthball"
            >
              fifthball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="fifthball"
              name="fifthball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fifthball}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label
              className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              htmlFor="sixthball"
            >
              sixthball
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="sixthball"
              name="sixthball"
              type="string"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sixthball}
            />
          </div>
        </div>

        <button
          className=" ml-60 shadow bg-green-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          type="submit"
        >
          Actual Feed
        </button>
      </form>
    </div>
  );
};

export default ManualResult;
