import React from "react";
import { useState } from "react";

import axios from "axios";

const CreateQuiz = () => {
  const [selectedFile, setselectedFile] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [theme, settheme] = useState("");
  const [time, settime] = useState("");
  const [tags, setTags] = useState("");
  const [isUnlisted, setIsUnlisted] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleStartTime, setScheduleStartTime] = useState(
    new Date().getTime() + 19800000
  );
  const [scheduleEndTime, setScheduleEndTime] = useState(
    new Date().getTime() + 19800000
  );

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const textHandler = (e) => {
    console.log(e.target.value);
    setcategoryName(e.target.value);
  };

  const settitleHandler = (e) => {
    console.log(e.target.value);
    settitle(e.target.value);
  };

  const setdescriptionHandler = (e) => {
    console.log(e.target.value);
    setdescription(e.target.value);
  };

  const setthemeHandler = (e) => {
    console.log(e.target.value);
    settheme(e.target.value);
  };

  const settimeHandler = (e) => {
    console.log(e.target.value);
    settime(e.target.value);
  };

  const fileSelectedHandler = (e) => {
    console.log(e.target.files);
    setselectedFile(e.target.files[0]);
  };
  const tagsHandler = (e) => {
    const tagsArray = e.target.value;
    setTags(tagsArray);
  };

  const dropdownOptions = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];

  const handleIsUnlistedChange = (e) => {
    setIsUnlisted(e.target.value);
  };
  const handleIsScheduledChange = (e) => {
    setIsScheduled(e.target.value);
  };

  const fileUploadHandler = () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("category", categoryName);
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("theme", theme);
    formdata.append("time", time);
    formdata.append("tags", tags);
    formdata.append("isUnlisted", isUnlisted);
    formdata.append("isScheduled", isScheduled);

    formdata.append("scheduleStartTime", new Date(scheduleStartTime).getTime());
    formdata.append("scheduleEndTime", new Date(scheduleEndTime).getTime());

    axios
      .post("https://api2.kjgsuskb.in/api/v1/admin/quiz/create", formdata, {
        headers: {
          "bwb-header":
            "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
        },
      })
      .then((res) => {
        alert("quiz created");
        console.log(res);
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };
  return (
    <div className="flex flex-col justify-center items-center border-2 py-8 px-6 shadow rounded-lg sm:px-10 bg-blue-400">

      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        style={{ zoom: 0.8 }}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Logo
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="file"
              name="file"
              onChange={fileSelectedHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Category:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={categoryName}
              name="category"
              id="category"
              onChange={textHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Title:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={title}
              name="title"
              id="title"
              onChange={settitleHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Description:
            </label>
          </div>
          <div className="md:w-2/3">
            <textarea
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={description}
              name="description"
              id="description"
              onChange={setdescriptionHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Theme:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={theme}
              name="theme"
              id="theme"
              onChange={setthemeHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Time:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="number"
              value={time}
              name="time"
              id="time"
              onChange={settimeHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Tags:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={tags}
              name="tags"
              id="tags"
              onChange={tagsHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Is Unlisted
            </label>
          </div>
          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              value={isUnlisted}
              onChange={handleIsUnlistedChange}
              name="isUnlisted"
            >
              {dropdownOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Is Scheduled
            </label>
          </div>
          <div className="md:w-2/3">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              value={isScheduled}
              onChange={handleIsScheduledChange}
              name="isScheduled"
            >
              {dropdownOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              scheduleStartTime:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="datetime-local"
              name="scheduleStartTime"
              value={scheduleStartTime}
              onChange={(e) => setScheduleStartTime(e.target.value)}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              scheduleEndTime:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="datetime-local"
              name="scheduleEndTime"
              value={scheduleEndTime}
              onChange={(e) => setScheduleEndTime(e.target.value)}
            />
          </div>
        </div>

        <button
          type="submit"
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          onClick={fileUploadHandler}
        >
          Create Quiz
        </button>
      </form>
    </div>
  );
};

export default CreateQuiz;
