// Sidebar.js
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { links, linksRole } from '../data (1)/data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import '../pages/Sidebar.css';  // Import the Sidebar.css file

const Sidebar = () => {
    const { activeMenu, setActiveMenu, screenSize } = useStateContext();
    const role = localStorage.getItem('Role');

    const handleCloseSidebar = () => {
        if (screenSize <= 900) {
            setActiveMenu(!activeMenu);
        }

           // Add the 'active' class to the selected sidebar link.
    const selectedLink = document.querySelector('.sidebar-link.active');
    selectedLink.classList.add('active');


    
  };



 
    return (
        <div className={`sidebar ${activeMenu ? 'active' : ''}`}>
            <div className="sidebar-header">
                <Link to="/" onClick={handleCloseSidebar} className="logo-container">
                <div className="logo-container">
                    <img src="quizwalla logo.jpeg" alt="logo" className="logo" />
                    </div>
                </Link>
            </div>

            <div className="sidebar-content">
                {(!role ? links : linksRole).map((item, index) => (
                    <div key={index} className="sidebar-section">
                        <p className="section-title">{item.title}</p>
                        {item.links.map((LinkItem, index) => (
                            <NavLink
                                to={`/${LinkItem.name}`}
                                key={index}
                                onClick={handleCloseSidebar}
                                className="sidebar-link"
                                activeClassName="active"
                            >
                                {LinkItem.icon}
                                <span className="link-label">{LinkItem.name}</span>
                            </NavLink>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
