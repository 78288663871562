import React, { useState, useEffect } from "react";
import axios from "axios";

const UserHistory2 = () => {
  const [mobile, setMobile] = useState("");
  const [userSummary, setUserSummary] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1); // Initialize the serial number counter

  useEffect(() => {
    const fetchUserSummary = async () => {
      try {
        if (mobile) {
          const response = await axios.get(
            `https://api2.kjgsuskb.in/api/v1/admin/user/summary/${mobile}`,
            {
              headers: {
                "bwb-header":
                  "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
              },
            }
          );
          setUserSummary(response.data.summary);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserSummary();
  }, [mobile]);

  return (
    <div className="p-4" style={{ zoom: 0.7 }}>
      <h2 className="text-2xl font-bold mb-4">User Summary</h2>
      <div className="mb-4">
        <input
          type="text"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          placeholder="Enter Mobile Number"
          className="px-2 py-1 border rounded"
        />
      </div>
      {userSummary.length > 0 ? (
        <div className="bg-white p-4 rounded shadow" style={{ zoom: 0.9 }}>
          <table className="w-full table-auto text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">Serial</th>{" "}
                {/* Added Serial column */}
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Amount/Return</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">AvailableB</th>
                <th className="px-4 py-2">Title</th>
                <th className="px-4 py-2">Details</th>
                <th className="px-4 py-2">mobile</th>
                <th className="px-4 py-2">name</th>
                <th className="px-4 py-2">paymentcreds</th>
                <th className="px-4 py-2">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {userSummary.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-200" : ""}
                >
                  <td className="px-4 py-2">{serialNumber + index}</td>{" "}
                  {/* Serial number */}
                  <td className="px-4 py-2">{item.type}</td>
                  <td className="px-4 py-2">{item.amount}</td>
                  <td className="px-4 py-2">{item.status}</td>
                  <td className="px-4 py-2">{item.availableCoins}</td>
                  <td className="px-4 py-2">{item.title}</td>
                  <td className="px-4 py-2">{item.details}</td>
                  <td className="px-4 py-2">{item.mobile}</td>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2">{JSON.stringify(item.paymentCredentials)}</td>
                  <td className="px-4 py-2">{item.timestamp}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-sm">
          {mobile
            ? "No user summary found."
            : "Enter a mobile number to get user summary."}
        </p>
      )}
    </div>
  );
};

export default UserHistory2;
