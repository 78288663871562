import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";


const AllPool = () => {
  const [user, setUser] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1); // Track the start index for the current page

  const fetchFunction = async () => {
    await axios
      .get(
        `https://api2.kjgsuskb.in/api/v1/admin/pool?page=${page}&itemsPerPage=${itemsPerPage}`,

        {
          headers: {
            "bwb-header":
              "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setUser(response.data?.pools);
        setStartIndex((page - 1) * itemsPerPage + 1); // Update the start index based on the current page and items per page
      });
  };

  useEffect(() => {
    fetchFunction();

    return () => {
      fetchFunction();
    };
  }, [page, itemsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  const deletePool = (pool) => {
    console.log("deletePool", pool);

    // Body create
    let apiBody = {
      poolid: pool,
    };

    // doing api call
    var config = {
      method: "delete",
      url: "https://api2.kjgsuskb.in/api/v1/admin/pool/delete",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("delete pool");
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const updatePool = (pool) => {
    console.log("poolupdate", pool);

    // Body create
    let apiBody = {
      poolid: pool,
    };

    // doing api call
    var config = {
      method: "post",
      url: "https://api2.kjgsuskb.in/api/v1/admin/pool/result",
      headers: {
        "Content-Type": "application/json",
        "bwb-header":
          "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
      },
      data: JSON.stringify(apiBody),
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        alert("pool result declared");
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  if (user?.length) {
    return (
      <div className="flex flex-col">
        {/* <Header category="Page" title="All Pool" /> */}
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-200 sm:divide-y-0"
                style={{ zoom: 0.52 }}
              >
                <thead className="bg-red-50">
                  <tr>
                    <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Serial
                    </th>
                    {Object.keys(user[0]).map((res, index) => {
                      if (res !== "isDeleted" && res !== "isReported") {
                        return (
                          <th
                            key={index}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {res === "walletCredentials" ? "coins" : res}
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {user.map((res, index) => {
                    return (
                      <tr key={index} className="bg-white hover:bg-gray-100">
                        <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                          {startIndex + index}{" "}
                          {/* Display the increasing index */}
                        </td>
                        <td className="px-2 py-4 text-sm font-medium text-gray-800 whitespace-nowrap  hover:bg-fuchsia-100">
                          {res?._id}
                        </td>
                        <td className="px-2 py-4 text-sm font-medium text-gray-800 whitespace-nowrap  hover:bg-fuchsia-100">
                          {res?.uid}
                        </td>
                        <td className="px-2 py-4 text-sm font-medium text-gray-800 whitespace-nowrap  hover:bg-fuchsia-100">
                          {res?.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.quizid}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.prizemoney}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.joinedPlayer}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.totalPlayer}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.expiryTime).toLocaleString()}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {res?.entryFee}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.reportedCount).toLocaleString()}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {new Date(res?.createdAt).toLocaleString()}
                        </td>

                        <td className="px-1 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {/* <button
                            className="shadow bg-blue-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              updatePool(res._id);
                            }}
                          >
                            pool result
                          </button> */}

                          <button
                            className="shadow ml-5 bg-blue-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => {
                              e.preventDefault();
                              deletePool(res._id);
                            }}
                          >
                            delete Pool
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page - 1)}
            disabled={page === 1}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Previous
          </button>
          <div className="mx-2"></div>
          <button
            style={{ zoom: 0.7 }}
            onClick={(event) => handlePageChange(event, page + 1)}
            disabled={user?.length < itemsPerPage}
            className="p-2 rounded-full bg-gray-300 hover:bg-gray-400 focus:outline-none focus:shadow-outline text-blue-500 -mx-2 -my-2 inline-block"
          >
            Next
          </button>
        </div>

        <div className="flex justify-center mt-4">
          <label className="mx-2 font-bold" style={{ zoom: 0.7 }}>
            Items per page:
          </label>
          <select
            style={{ zoom: 0.7 }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none focus:shadow-outline -mx-2 -my-2 inline-block"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        </div>
      </div>
    );
  }
};

export default AllPool;
