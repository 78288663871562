import React from "react";
import { useState } from "react";
import axios from "axios";
import { is } from "date-fns/locale";

const CreateCateg = () => {
  const [selectedFile, setselectedFile] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [isUnlisted, setIsUnlisted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const textHandler = (e) => {
    console.log(e.target.value);
    setcategoryName(e.target.value);
  };

  const fileSelectedHandler = (e) => {
    console.log(e.target.files);
    setselectedFile(e.target.files[0]);
  };

  const fileUploadHandler = () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    const categoryName = document.getElementById("categoryName");
    formdata.append("name", categoryName.value);
    formdata.append("isUnlisted",isUnlisted)

    axios
      .post("https://api2.kjgsuskb.in/api/v1/admin/category/create", formdata, {
        headers: {
          "bwb-header":
            "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
        },
      })
      .then((res) => {
        alert("category created");
        console.log(res);
        window.location.reload();
      })
      .catch(function (error) {
        alert("something went wrong");

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const dropdownOptions = [
    { value: true, label: "true" },
    { value: false, label: "false" },
  ];
  
  const handleIsUnlistedChange = (e) => {
    setIsUnlisted(e.target.value);
  };
  return (
    <div className="flex flex-col justify-center items-center border-2 py-8 px-6 shadow rounded-lg sm:px-10 bg-blue-400">

      <form
        className="w-full max-w-lg font-bold text-1xl text-white outline-4 "
        style={{ zoom: 0.8 }}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              Logo
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="file"
              name="file"
              onChange={fileSelectedHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
              CategoryName:
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              type="text"
              value={categoryName}
              name="name"
              id="categoryName"
              onChange={textHandler}
            />
          </div>
        </div>

        <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-900 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Is Unlisted
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            value={isUnlisted}
            onChange={handleIsUnlistedChange}
            name="isUnlisted"

          >
            {dropdownOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
        <button
          type="submit"
          
          className=" ml-60 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded "
          onClick={fileUploadHandler}
        >
          Create Category
        </button>
      </form>
    </div>
  );
};

export default CreateCateg;