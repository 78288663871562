import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Report = () => {
  const location = useLocation();
  const report = location.state.report;
  console.log("report => " + report);

  const [serialNumber, setSerialNumber] = useState(1);

  return (
    <div className="p-4" style={{ zoom: 0.7 }}>
      <h2 className="text-2xl font-bold mb-4">Detailed Report</h2>
      {report && report.length > 0 ? (
        <div className="bg-white p-4 rounded shadow" style={{ zoom: 0.9 }}>
          <table className="w-full table-auto text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">Serial</th>{" "}
                {/* Added Serial column */}
                <th className="px-4 py-2">MatchId</th>
                <th className="px-4 py-2">Mobile</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">UserUid</th>
                <th className="px-4 py-2">IsPredictionDone</th>
                <th className="px-4 py-2">IsResultDeclared</th>
                <th className="px-4 py-2">PredictionOver</th>
                <th className="px-4 py-2">InningsId</th>
                <th className="px-4 py-2">Prediction</th>
                <th className="px-4 py-2">Actual</th>
              </tr>
            </thead>
            <tbody>
              {report.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-200" : ""}
                >
                  <td className="px-4 py-2">{serialNumber + index}</td>{" "}
                  {/* Serial number */}
                  <td className="px-4 py-2">{item.matchId}</td>
                  <td className="px-4 py-2">{item.mobile}</td>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2">{item.userUid}</td>
                  <td className="px-4 py-2">
                    {item.isPredictionDone === true ? "true" : "false"}
                  </td>
                  <td className="px-4 py-2">
                    {item.isResultDeclared === true ? "true" : "false"}
                  </td>
                  <td className="px-4 py-2">{item.predictionOver}</td>
                  <td className="px-4 py-2">{item.inningsId}</td>
                  <td className="px-4 py-2">
                    {JSON.stringify(item.prediction)}
                  </td>
                  <td className="px-4 py-2">
                    {JSON.stringify(item.actual)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-sm">{report ? "No Detailed Report found" : ""}</p>
      )}
    </div>
  );
};

export default Report;
