import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import "bootstrap/dist/css/bootstrap.min.css";

import { Navbar, Footer, Sidebar, ThemeSetting, Login } from "./component";
import {
  Dashboard,
  AllCategory,
  Calendar,
  AllUser,
  AllQuiz,
  CreateQuestion,
  CreateQuiz,
  CreateCateg,
  CreatePool,
  CreateSinglePool,
  AllPool,
  AllQuestion,
  AllSinglePool,
  UpdateCoin,
  Bank,
  Bank2,
  AcceptedWithdrawal,
  RejectedWithdrawal,
  AcceptedAdd,
  RejectedAdd,
  Account,
  UpdateQuestion,
  UpdateQuiz,
  AllDeposit,
  UserHistory,
  ExpiredPool,
  AppConfig,
  OfferNotification,
  GetByDecreasingCoin,
  GetUserUsingMobile,
  GetAllPoolJoinedPlayer,
  ReportedPool,
  ReportedSinglePool,
  UserHistory2,
  Notification,
  UpdateCategory,
  NumberOfBots,
} from "./pages";

import { useStateContext } from "./contexts/ContextProvider";

import "./App.css";
import UsersPerGame from "./pages/UsersPerGame";
import ManualResult from "./pages/ManualResult";
import DetailedReport from "./pages/DetailedReport";
import Report from "./pages/Report";
import CreateQuestionForm from "./pages/CreateQuestionForm";
import QuestionListPage from "./pages/QuestionListPage";
import PostAnswerPage from "./pages/PostAnswerPage";
import CreateAdd from "./pages/CreateAdd";
import GetAdd from "./pages/GetAdd";
import GameImage from "./pages/GameImage";
import AllActiveUsers from "./pages/AllActiveUsers";
import UpdateActiveCoin from "./pages/updateactivecoin";
import NotificationActiveUser from "./pages/notificationActiveUser";

const App = () => {
  const { activeMenu } = useStateContext();
  const userToken = localStorage.getItem("Token");
  const role = localStorage.getItem("Role");

  return (
    <div>
      <BrowserRouter>
        {!userToken ? (
          <div>
            <Routes>
              <Route path="/" element={<Login />} />
            </Routes>
          </div>
        ) : (
          <div className="flex relative dark:bg-main-dark-bg">
            <div
              className="fixed right-4 bottom-4"
              style={{ zIndex: "1000" }}
            ></div>
            {activeMenu ? (
              <div className="w-65 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}

            <div
              className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
                activeMenu ? "md:ml-72" : "flex-2"
              }`}
            >
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar />
              </div>
              {!role ? (
                <div>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/alluser" element={<AllUser />} />
                    <Route path="/updatecoin" element={<UpdateCoin />} />
                    <Route path="/GameDetails" element={<UsersPerGame />} />
                    <Route path="/ManualResult" element={<ManualResult />} />
                    {/* <Route path="/DetailedReport" element={<DetailedReport />} /> */}
                    <Route path="/Report" element={<Report />} />
                    <Route path="/CreatedWithdrawal" element={<Bank />} />
                    <Route
                      path="/AcceptedWithdrawal"
                      element={<AcceptedWithdrawal />}
                    />
                    <Route
                      path="/RejectedWithdrawal"
                      element={<RejectedWithdrawal />}
                    />

                    <Route path="/CreatedDeposit" element={<AllDeposit />} />
                    <Route path="/AcceptedDeposit" element={<AcceptedAdd />} />
                    <Route path="/RejectedDeposit" element={<RejectedAdd />} />
                    <Route path="/GameImage" element={<GameImage />} />
                    <Route
                      path="/CreateQuickQsn"
                      element={<CreateQuestionForm />}
                    />
                    <Route path="/GetQuickQsn" element={<QuestionListPage />} />
                    <Route path="/PostAnswer" element={<PostAnswerPage />} />
                    <Route path="/CreateAdd" element={<CreateAdd />} />
                    <Route path="/GetAdd" element={<GetAdd />} />
                    <Route
                      path="/GetActiveUsers"
                      element={<AllActiveUsers />}
                    />
                    <Route
                      path="/updatecoinactiveuser"
                      element={<UpdateActiveCoin />}
                    />
                    <Route
                      path="/notifyactiveuser"
                      element={<NotificationActiveUser />}
                    />
                    <Route path="/UserHistory" element={<UserHistory2 />} />
                    <Route path="/Notification" element={<Notification />} />
                  </Routes>
                </div>
              ) : (
                <div>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/allcategory" element={<AllCategory />} />
                    <Route path="/allpool" element={<AllPool />} />
                    {/* <Route path="/allsinglepool" element={<AllSinglePool />} /> */}
                    {/* <Route path="/alldeposit" element={<AllDeposit />} /> */}

                    <Route path="/allquiz" element={<AllQuiz />} />
                    <Route path="/allquestion" element={<AllQuestion />} />
                    <Route path="/createcateg" element={<CreateCateg />} />
                    <Route path="/createpool" element={<CreatePool />} />
                    {/* <Route path="/createpool" element={<CreateSinglePool />} /> */}

                    <Route path="/createquiz" element={<CreateQuiz />} />
                    {/* <Route
                      path="/updatequestion"
                      element={<UpdateQuestion />}
                    /> */}
                    <Route
                      path="/createquestion"
                      element={<CreateQuestion />}
                    />

                    {/* <Route path="/calendar" element={<Calendar />} /> */}
                    {/* <Route path="/alluser" element={<AllUser />} /> */}
                  </Routes>
                </div>
              )}
            </div>
          </div>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
