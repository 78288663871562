import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AllActiveUsers = () => {
  let navigate = useNavigate();

  const [user, setUser] = useState([]);
  const [startIndex, setStartIndex] = useState(1);

  useEffect(() => {
    const fetchFunction = async () => {
      try {
        const response = await axios.get(
          `https://api2.kjgsuskb.in/api/v1/admin/users/active/decreasing`,
          {
            headers: {
              "bwb-header":
                "22a0d160c86894ca7cf6d8bcb5e7095269308613f317e90bdfb12e39a62h6t5b",
            },
          }
        );
        console.log(response.data?.data);
        setUser(response.data?.data);
        setStartIndex((1 - 1) * 1000 + 1);
      } catch (error) {
        console.log("Error fetching user data:", error);
      }
    };
    fetchFunction();
  }, []);

  //   useEffect(() => {
  //     filterUser();
  //   }, [user, searchQuery]);

  const updateCoinHandler = (mobile) => {
    navigate("/updatecoinactiveuser", { state: mobile });
  };

  const notificationHandler = (mobile) => {
    navigate("/notifyactiveuser", { state: mobile });
  };

  // if (user.length) {

  if (!user || user.length === 0) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center mt-2 space-x-2"></div>

      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200 sm:divide-y-0"
              style={{ zoom: 0.55 }}
            >
              <thead className="bg-red-50">
                <tr>
                  <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Serial
                  </th>
                  {Object.keys(user[0]).map((res, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {res}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {user.map((res, index) => {
                  return (
                    <tr key={index} className="bg-white hover:bg-gray-100">
                      <td className="px-4 py-2 border-2  hover:bg-fuchsia-100">
                        {startIndex + index}{" "}
                        {/* Display the increasing index */}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.noOfGames}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.mobile}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.userUid}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {res?.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-green-700 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            updateCoinHandler(res.mobile);
                          }}
                        >
                          Add Coin
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          className="shadow bg-pink-700 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            notificationHandler(res.mobile);
                          }}
                        >
                          Send Notification
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllActiveUsers;
